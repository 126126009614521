import './add.css';

function Add() {
  return (
    <div className="add-container">
      <h1>We provide FREE estimates</h1>
    </div>
  );
}

export default Add;
