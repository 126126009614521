import './second-add.css';

function SecondAdd() {
  return (
    <div className="second-add-container">
      <h1>The Best Services in South Carolina</h1>
      <h2>Create your customized cleaning plan today </h2>
    </div>
  );
}

export default SecondAdd;
